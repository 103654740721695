import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

import { getAuthError } from '@/redux/auth/selectors'
import { getPasswordChangeError } from '@/redux/profile/selectors'
import ROUTES from '@helpers/routes'

import StyleableBanner from '../styleable-banner'
import { ERROR } from '../types'

export default function LoginSignupErrorBanner() {
  const router = useRouter()
  const authError = useSelector(getAuthError)
  const changePasswordError = useSelector(getPasswordChangeError)

  return changePasswordError?.includes('password reset session') ? (
    <StyleableBanner
      bannerText={{ message: changePasswordError, underlinedMessage: 'Please reset your password again.' }}
      onClick={() => router.push(ROUTES.RESET_PASSWORD)}
      bannerType={ERROR}
    />
  ) : (
    <StyleableBanner bannerText={{ message: authError || changePasswordError }} bannerType={ERROR} />
  )
}
