import { useSelector } from 'react-redux'

import { getCheckoutError } from '@/redux/checkout/selectors'

import { InferProps, bool } from 'prop-types'

import StyleableBanner from '../styleable-banner'
import { ERROR } from '../types'

export const SIFT_CARD_DECLINED_ERROR = 'Order declined, our heuristics indicate a high possibility of fraud.'

export default function CheckoutErrorBanner({ inCartDrawer }: InferProps<typeof CheckoutErrorBanner.propTypes>) {
  const checkoutError = useSelector(getCheckoutError)
  let errorMessage = checkoutError || ''

  if (errorMessage) {
    if (errorMessage?.match(/PreAuth_Error/) || errorMessage?.match(/Prauth/)) {
      errorMessage = 'We were unable to process your card. Please add a different card and try again.'
    } else if (errorMessage === SIFT_CARD_DECLINED_ERROR) {
      // friendlier copy for sift science declined cards
      errorMessage = "We're having issues processing your card. But don't worry, you can always pay with cash."
    } else if (errorMessage?.match(/Invalid data in body/)) {
      errorMessage = 'Please confirm your credit card details and try again.'
    }
    return <StyleableBanner bannerText={{ message: errorMessage }} bannerType={ERROR} inCartDrawer={inCartDrawer} />
  }
  return null
}
CheckoutErrorBanner.propTypes = {
  inCartDrawer: bool
}
