import { useDispatch, useSelector } from 'react-redux'

import { toggleReferralModal } from '@/redux/modal/actions'
import { getCreditAmount } from '@/redux/profile/selectors'
import { numberToPrice } from '@helpers/price-helpers'

import { InferProps, bool } from 'prop-types'

import StyleableBanner from '../styleable-banner'
import { SUCCESS } from '../types'

export default function CreditBanner({ inCartDrawer }: InferProps<typeof CreditBanner.propTypes>) {
  const dispatch = useDispatch()
  const creditAmount = useSelector(getCreditAmount)
  const message = `You have ${numberToPrice(creditAmount)} available for this order! To earn more credit,`

  return (
    <StyleableBanner
      onClick={() => {
        dispatch(toggleReferralModal())
      }}
      bannerText={{ message, underlinedMessage: 'invite your friends.' }}
      bannerType={SUCCESS}
      inCartDrawer={inCartDrawer}
    />
  )
}
CreditBanner.propTypes = {
  inCartDrawer: bool
}
