import { Alerts } from '@/redux/alert/config/types'
import {
  DELIVERY_FEE_MESSAGING,
  DISCOUNTED_DELIVERY_BYPASS_KEY,
  DISCOUNTED_DELIVERY_FEE_KEY
} from '@helpers/order-summary/message-formatters'

const alertText: { [K in Alerts]?: { message: string; underlinedMessage?: string } } = {
  CANCELED: { message: 'Your order was canceled by the driver. Please try again!' },
  DISCOUNTED_DELIVERY_BYPASS: { message: DELIVERY_FEE_MESSAGING[DISCOUNTED_DELIVERY_BYPASS_KEY] },
  DISCOUNTED_DELIVERY_FEE: { message: DELIVERY_FEE_MESSAGING[DISCOUNTED_DELIVERY_FEE_KEY] },
  EXISTING_EMAIL_ADDRESS: {
    message: 'This email is associated with another Eaze account.',
    underlinedMessage: 'Please contact support for additional help.'
  },
  EXISTING_PHONE_NUMBER: {
    message: 'This phone number is associated with another Eaze account.',
    underlinedMessage: 'Please contact support for additional help.'
  },
  EXPIRED_ID: { message: 'Your ID is expired.', underlinedMessage: 'Upload an unexpired ID.' },
  EXPIRED_REC: { message: 'Your recommendation has expired!', underlinedMessage: 'Update your account.' },
  HAS_LINKED_ACCOUNTS: { message: 'You already have an Eaze account.', underlinedMessage: 'Log in.' },
  ID_DECLINED: { message: 'There was an issue with your ID.', underlinedMessage: 'Please try uploading it again.' },
  ID_INVALID_IN_AREA: {
    message: 'Your ID type is not valid for delivery in this location. Change your location or',
    underlinedMessage: 'upload another ID.'
  },
  ID_PENDING: {
    message:
      "We're currently experiencing an outage in our ID verification systems. We'll message you once the issue is resolved."
  },
  ID_REQUIRED: {
    message: 'We need to see your ID before you can place an order on Eaze.',
    underlinedMessage: 'Complete your verification.'
  },
  INVALID_LOGIN: { message: 'Your email and password combination did not match an existing user. Please try again.' },
  INVALID_STREET_ADDRESS: { message: 'Please enter a full street address for delivery.' },
  FREE_DELIVERY: { message: 'Free delivery for all of August! Get some summer savings!' },
  LOCATION: {
    message: "We aren't in your area yet, but we're always adding new areas. Check out our sample menu in the meantime."
  },
  LOCATION_OUT_OF_SERVICE: { message: "We aren't available in your area." },
  NO_DRIVERS: { message: 'Sorry, no drivers are currently available to fulfill orders.' },
  PHONE_VERIFICATION_INCOMPLETE: {
    message: 'We need to verify your phone number before you can place an order on Eaze.',
    underlinedMessage: 'Verify phone number.'
  },
  PRODUCTS_UNAVAILABLE: {
    message:
      'Sorry, there are no drivers who are able to fulfill your entire order. Please try a different combination of products.'
  },
  VERIFICATION_PENDING: {
    message: "We're reviewing your information. You'll be able to order to this address once you're verified."
  },
  VERIFICATION_RESEND_SUCCESSFUL: { message: 'Verification text was resent.' }
}

export default alertText
