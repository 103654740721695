import { useRouter } from 'next/router'

import { dispatchIdClickoutEvent, isInAppView } from 'helpers/is-mobile'
import ROUTES from 'helpers/routes'
import { alertTypes } from 'redux/alert/config/types'

import alertText from './alert-text'
import CheckoutErrorBanner from './alerts/checkout-error'
import CheckoutMessageBanner from './alerts/checkout-message'
import ClosingSoonBanner from './alerts/closing-soon'
import CreditBanner from './alerts/credit'
import AtDailyLimitBanner from './alerts/daily-limit'
import ExpiredIdBanner from './alerts/expired-id'
import LoginSignupErrorBanner from './alerts/login-signup-error'
import StoreClosedBanner from './alerts/store-closed'
import UnderMinimumBanner from './alerts/under-minimum'
import VerificationProcessErrorBanner from './alerts/verification-process-error'
import StyleableBanner from './styleable-banner'
import { ERROR, INFO, SUCCESS } from './types'

import { InferProps, bool, string } from 'prop-types'

export default function Banner({ alertKey, inCartDrawer }: InferProps<typeof Banner.propTypes>) {
  const router = useRouter()
  const route = router.pathname

  let banner: JSX.Element

  const navigateToVerify = () => {
    // If we are in the webview app, don't redirect to verify page, just dispatch an event (handling in app)
    if (isInAppView) {
      dispatchIdClickoutEvent()
      return
    }
    router.push(ROUTES.VERIFY)
  }

  switch (alertKey) {
    case alertTypes.CHECKOUT: {
      banner = <CheckoutErrorBanner inCartDrawer={inCartDrawer} />
      break
    }

    case alertTypes.CHECKOUT_MESSAGE: {
      banner = <CheckoutMessageBanner inCartDrawer={inCartDrawer} />
      break
    }

    case alertTypes.CREDIT:
      banner = <CreditBanner inCartDrawer={inCartDrawer} />
      break

    case alertTypes.EXISTING_EMAIL_ADDRESS:
    case alertTypes.EXISTING_PHONE_NUMBER:
      banner = (
        <StyleableBanner
          onClick={() => router.push(`${ROUTES.MENU}?help=true&form=contact`)}
          bannerText={alertText[alertKey]}
          bannerType={ERROR}
          inCartDrawer={inCartDrawer}
        />
      )
      break

    case alertTypes.EXPIRED_REC:
      banner = (
        <StyleableBanner
          onClick={() => router.push(ROUTES.PROFILE)}
          bannerText={alertText[alertKey]}
          bannerType={ERROR}
          inCartDrawer={inCartDrawer}
        />
      )
      break

    case alertTypes.ID_DECLINED:
    case alertTypes.ID_INVALID_IN_AREA:
    case alertTypes.ID_REQUIRED:
      banner = (
        <StyleableBanner
          bannerText={alertText[alertKey]}
          onClick={navigateToVerify}
          bannerType={route === ROUTES.CHECKOUT ? ERROR : INFO}
          inCartDrawer={inCartDrawer}
        />
      )
      break

    case alertTypes.HAS_LINKED_ACCOUNTS:
      banner = (
        <StyleableBanner
          bannerText={alertText[alertKey]}
          onClick={() => router.push(ROUTES.LOGIN)}
          bannerType={route === ROUTES.CHECKOUT ? ERROR : INFO}
          inCartDrawer={inCartDrawer}
        />
      )
      break

    case alertTypes.INVALID_STREET_ADDRESS:
    case alertTypes.VERIFICATION_PENDING:
      banner = (
        <StyleableBanner
          bannerText={alertText[alertKey]}
          bannerType={route === ROUTES.CHECKOUT ? ERROR : INFO}
          inCartDrawer={inCartDrawer}
        />
      )
      break

    case alertTypes.LOGIN_OR_SIGNUP_ERROR:
      banner = <LoginSignupErrorBanner />
      break

    case alertTypes.PHONE_VERIFICATION_INCOMPLETE:
      banner = (
        <StyleableBanner
          bannerText={alertText[alertKey]}
          onClick={() => router.push(ROUTES.VERIFY)}
          bannerType={route === ROUTES.CHECKOUT ? ERROR : INFO}
          inCartDrawer={inCartDrawer}
        />
      )
      break

    // This is an unused alert - triggering /legal purchase limit/ error uses the CHECKOUT alert type
    case alertTypes.AT_DAILY_LIMIT:
      banner = <AtDailyLimitBanner inCartDrawer={inCartDrawer} />
      break

    case alertTypes.STORE_CLOSED: {
      banner = <StoreClosedBanner inCartDrawer={inCartDrawer} />
      break
    }

    case alertTypes.STORE_CLOSING_SOON:
      banner = <ClosingSoonBanner inCartDrawer={inCartDrawer} />
      break

    case alertTypes.UNDER_MINIMUM:
      banner = <UnderMinimumBanner route={route} inCartDrawer={inCartDrawer} />
      break

    case alertTypes.VERIFICATION_PROCESS_ERROR:
      banner = <VerificationProcessErrorBanner inCartDrawer={inCartDrawer} />
      break

    case alertTypes.EXPIRED_ID:
      banner = <ExpiredIdBanner inCartDrawer={inCartDrawer} />
      break

    case alertTypes.DISCOUNTED_DELIVERY_FEE:
    case alertTypes.DISCOUNTED_DELIVERY_BYPASS:
    case alertTypes.FREE_DELIVERY:
      banner = <StyleableBanner bannerText={alertText[alertKey]} bannerType={SUCCESS} inCartDrawer={inCartDrawer} />
      break

    case alertTypes.ID_PENDING:
    case alertTypes.INVALID_LOGIN:
    case alertTypes.NO_DRIVERS:
    case alertTypes.PRODUCTS_UNAVAILABLE:
      banner = <StyleableBanner bannerText={alertText[alertKey]} bannerType={ERROR} inCartDrawer={inCartDrawer} />
      break

    case alertTypes.VERIFICATION_RESEND_SUCCESSFUL:
    case alertTypes.LOCATION_OUT_OF_SERVICE:
    default:
      banner = <StyleableBanner bannerText={alertText[alertKey]} bannerType={INFO} inCartDrawer={inCartDrawer} />
  }

  return banner
}
Banner.propTypes = {
  alertKey: string,
  inCartDrawer: bool
}
