/**
 * Under minimum banners should be error red in checkout but only a warning (yellow/orange) in menu
 */
import { useEffect } from 'react'

import { useSelector } from 'react-redux'

import { getDepotMinimumOrder } from '@/redux/location/selectors'
import { getSubtotal } from '@/redux/quote/selectors'
import errorHandler from '@helpers/error-handler'
import ROUTES, { SHOPPING_PATHS } from 'helpers/routes'

import { InferProps, bool, string } from 'prop-types'

import StyleableBanner from '../styleable-banner'
import { ERROR, ErrorBannerTypes, WARNING } from '../types'

// @TODO: route should be a required prop and/or we need a default for type when route is not present
export default function UnderMinimumBanner({ inCartDrawer, route }: InferProps<typeof UnderMinimumBanner.propTypes>) {
  const cartMinimum = useSelector(getDepotMinimumOrder)
  const subtotal = useSelector(getSubtotal)

  useEffect(() => {
    if (subtotal && subtotal >= cartMinimum) {
      errorHandler(new Error(`cart_minimum_mismatch: cartMinimum: ${cartMinimum}, subTotal: ${subtotal}`))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  let type: ErrorBannerTypes

  if (route === ROUTES.CHECKOUT) {
    type = ERROR
  } else if (SHOPPING_PATHS.some((path) => route.includes(path))) {
    type = WARNING
  }

  const message = `Your cart is under the $${cartMinimum} minimum.`

  return <StyleableBanner data-sentry-unmask bannerText={{ message }} bannerType={type} inCartDrawer={inCartDrawer} />
}
UnderMinimumBanner.propTypes = {
  inCartDrawer: bool,
  route: string
}
