import { useDispatch } from 'react-redux'

import { alertTypes } from '@/redux/alert/config/types'
import { track } from 'analytics'
import { dispatchIdClickoutEvent, isInAppView } from 'helpers/is-mobile'
import { togglePersonaModal } from 'redux/modal/actions'

import { InferProps, bool } from 'prop-types'

import alertText from '../alert-text'
import StyleableBanner from '../styleable-banner'
import { WARNING } from '../types'

export default function ExpiredIdBanner({ inCartDrawer }: InferProps<typeof ExpiredIdBanner.propTypes>) {
  const dispatch = useDispatch()

  const handleOpeningModal = () => {
    if (isInAppView) {
      dispatchIdClickoutEvent()
      return // Don't want to double-count the event in analytics or open the broken modal (it would just show the overlay)
    }
    track('Signup.StateId.Upload')
    dispatch(togglePersonaModal())
  }

  return (
    <StyleableBanner
      bannerType={WARNING}
      bannerText={alertText[alertTypes.EXPIRED_ID]}
      onClick={handleOpeningModal}
      inCartDrawer={inCartDrawer}
    />
  )
}
ExpiredIdBanner.propTypes = {
  inCartDrawer: bool
}
