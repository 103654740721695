import { useSelector } from 'react-redux'

import { getCheckoutError } from '@/redux/checkout/selectors'

import { InferProps, bool } from 'prop-types'

import StyleableBanner from '../styleable-banner'
import { ERROR } from '../types'

// This is an unused banner - triggering /legal purchase limit/ error uses the checkout-error banner
export default function AtDailyLimitBanner({ inCartDrawer }: InferProps<typeof AtDailyLimitBanner.propTypes>) {
  const checkoutError = useSelector(getCheckoutError)
  return <StyleableBanner bannerText={{ message: checkoutError }} bannerType={ERROR} inCartDrawer={inCartDrawer} />
}
AtDailyLimitBanner.propTypes = {
  inCartDrawer: bool
}
