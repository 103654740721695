import { useSelector } from 'react-redux'

import { getVerificationProcessErrors } from '@/redux/verification/selectors'

import { InferProps, bool } from 'prop-types'

import StyleableBanner from '../styleable-banner'
import { ERROR } from '../types'

export default function VerificationProcessErrorBanner({
  inCartDrawer
}: InferProps<typeof VerificationProcessErrorBanner.propTypes>) {
  const verificationProcessErrors = useSelector(getVerificationProcessErrors)
  return (
    <StyleableBanner
      bannerText={{ message: verificationProcessErrors.generic }}
      bannerType={ERROR}
      inCartDrawer={inCartDrawer}
    />
  )
}
VerificationProcessErrorBanner.propTypes = {
  inCartDrawer: bool
}
