import fecha from 'fecha'
import { useSelector } from 'react-redux'

import { getOpenTime } from '@/redux/location/selectors'

import { InferProps, bool } from 'prop-types'

import StyleableBanner from '../styleable-banner'
import { SUCCESS } from '../types'

export default function StoreClosedBanner({ inCartDrawer }: InferProps<typeof StoreClosedBanner.propTypes>) {
  const openTime = useSelector(getOpenTime)
  const message = `Schedule a delivery! We begin deliveries at ${fecha.format(openTime, 'h:mm A')}.`

  return <StyleableBanner bannerText={{ message }} bannerType={SUCCESS} inCartDrawer={inCartDrawer} />
}
StoreClosedBanner.propTypes = {
  inCartDrawer: bool
}
