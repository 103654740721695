/**
 * Closing Soon banner
 */

import { useSelector } from 'react-redux'

import { getCloseTime } from '@/redux/location/selectors'
import { parseClosingTime } from '@helpers/date'

import { InferProps, bool } from 'prop-types'

import StyleableBanner from '../styleable-banner'
import { WARNING } from '../types'

export default function ClosingSoonBanner({ inCartDrawer }: InferProps<typeof ClosingSoonBanner.propTypes>) {
  const closingTime = useSelector(getCloseTime)
  const timeString = parseClosingTime(closingTime)
  const message = `Eaze is closing soon. Please place your order before ${timeString}.`

  return <StyleableBanner bannerText={{ message }} bannerType={WARNING} inCartDrawer={inCartDrawer} />
}

ClosingSoonBanner.propTypes = {
  inCartDrawer: bool
}
