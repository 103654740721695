import { useSelector } from 'react-redux'

import { getCheckoutMessage, getCheckoutMessageStatusCode } from '@/redux/checkout/selectors'
import { STATUS_CODE_DELIVERY_DECREASED, STATUS_CODE_DELIVERY_INCREASED } from '@helpers/constants'

import { InferProps, bool } from 'prop-types'

import StyleableBanner from '../styleable-banner'
import { ERROR, SUCCESS } from '../types'

export default function CheckoutMessageBanner({ inCartDrawer }: InferProps<typeof CheckoutMessageBanner.propTypes>) {
  const checkoutMessage = useSelector(getCheckoutMessage)
  const checkoutMessageStatusCode = useSelector(getCheckoutMessageStatusCode)
  const alertStyles = {
    [STATUS_CODE_DELIVERY_INCREASED]: ERROR,
    [STATUS_CODE_DELIVERY_DECREASED]: SUCCESS
  }

  return (
    <StyleableBanner
      bannerType={alertStyles[checkoutMessageStatusCode]}
      bannerText={{ message: checkoutMessage }}
      inCartDrawer={inCartDrawer}
    />
  )
}
CheckoutMessageBanner.propTypes = {
  inCartDrawer: bool
}
