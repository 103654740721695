import { lightTheme } from '@/theme'

/**
 * Banner type constants to be used for different banner states.
 * - ERROR: Red - #FF4069
 * - INFO: Black - #000000
 * - WARNING: Orange - #FFB65C
 * - SUCCESS: Green - #A5F0A9
 */
export const ERROR = 'ERROR'
export const INFO = 'INFO'
export const WARNING = 'WARNING'
export const SUCCESS = 'SUCCESS'

/**
 * Array of error-related banner types.
 * Use these types for banners that indicate errors or warnings.
 */
export const errorOptions = [ERROR, INFO, WARNING] as const
export type ErrorBannerTypes = typeof errorOptions[number]

/**
 * Array of all possible banner types.
 * Use these types for any kind of banner.
 */
export const bannerOptions = [...errorOptions, SUCCESS] as const
export type BannerTypes = typeof bannerOptions[number]

/**
 * Function to get the background color for a given banner type.
 * @param type - The type of the banner.
 * @returns The background color corresponding to the banner type.
 * - ERROR: Red - #FF4069
 * - WARNING: Orange - #FFB65C
 * - SUCCESS: Green - #A5F0A9
 * - INFO: Black - #000000
 */
export const getBannerBackgroundColor = (type: string): string => {
  switch (type) {
    case ERROR:
      return lightTheme.colors.bannerError
    case WARNING:
      return lightTheme.colors.bannerWarning
    case SUCCESS:
      return lightTheme.colors.bannerSuccess
    case INFO:
      return lightTheme.colors.bannerInfo
    default:
      return lightTheme.colors.bannerInfo
  }
}
